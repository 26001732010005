<template>
  <div class="matchPage">
    <div class="patchContent" v-if="show == true">
      <div class="banner">
        <div class="finishImg">
           <img src="../../img/da2.png" alt="" />
        </div>
        <p class="matchTitle">匹配到 {{ endVal }} 个贷款产品</p>
      </div>
      <p
        style="
          font-size: 15px;
          font-weight: 400;
          padding: 15px 57px;
          font-family: PingFangSC-Regular, PingFang SC;
        "
      ></p>
      <div class="buttons"></div>
    </div>
    <div class="patchContent" v-else>
      <div class="banner">
        <div class="loadingImg">
          <img src="../../img/da2.png" alt="" />
        </div>
        <p class="arktitle">系统正在进行智能匹配 请稍后</p>
        <p style="font-size: 15px; margin-top: 40px">
          已匹配到
          <countTo
            :endVal="endVal"
            :duration="6000"
          ></countTo
          >个产品,请稍等...
        </p>
      </div>
    </div>
    <div class="footer">
      <p>
        <i class="iconfont iconyingyongbeifen"></i>系统全程严格保证企业数据安全
      </p>
    </div>
  </div>
</template>
<script>
import url from "@/service/url-config.js";
import countTo from "vue-count-to";
export default {
  components: { countTo },
  data() {
    return {
      startVal: 0,
      endVal: 0,
      show: false,
      timer: "",
      count: 30,
      turn: 0,
      name: "",
      param: {
        taxAccount: '',
        loanPredictionId:'',
        companyId:'',
      },
    };
  },
  created(){

  },
  mounted() {
       this.param= {
        taxAccount: this.$route.query.taxAccount,
        loanPredictionId:this.$route.query.loanPredictionId,
        companyId:this.$route.query.companyId,
      }
    this.companyInfo();
  },
  methods: {
    // 匹配产品个数
    async companyInfo() {
      const { code, data } = await this.$http.get(url.getLoanPredictionBeforeAuth+this.param.loanPredictionId);
      if (code == 200) {
        this.show = true;
        const { productCount, isAuth,allQuota,taxAccount,corporation,name,loanPredictionId} = data
        this.endVal = productCount;
        setTimeout(() => {
          this.$router.replace({ path: "result",query:{ productCount, isAuth,taxAccount,loanPredictionId,corporation,name,allQuota} ,replace:true});
        }, 2000);
      } else if (202 == code) {
        this.timer = setTimeout(() => {
          if (this.count == 1) {
            this.turn++;
            if (this.turn == 3) {
              this.$toast("查询失败");
              clearInterval(this.timer);
              this.$router.replace({name:"measurement" ,replace:true});
              return;
            }
          }
          this.count--;
          if (this.count > 0) {
            this.companyInfo();
          } else {
            this.secondePostInfo();
          }
        }, 1000);
      }
    },
    async secondePostInfo() {
      const {
        code,
        data: { loanPredictionId },
        // msg,
      } = await this.$http.post(url.loginCompensation, {
        companyId: this.param.companyId,
        loanPredictionId: this.param.loanPredictionId,
        origin: 72
      });
      if (code == 200) {
        this.param.loanPredictionId = loanPredictionId;
        this.count = 30;
        this.companyInfo();
      } else {
        // this.$toast(msg);
        this.$router.replace({name:'measurement',replace:true});
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>
<style lang="less" scoped>

.matchPage {
  background-color: #0084ff;
  height: 100vh;
  .patchContent {
    color: #fff;
    min-height: calc(100vh - 202px);
    box-sizing: border-box;
    padding-top: 60px;
    text-align: center;
    .banner {
      padding: 40px 77px;
      margin-top: 24px;
    }
    .finishImg {
      width: 81px;
      height: 64px;
      margin: auto;
      background: url("../../img/da1.png") no-repeat center;
      background-size: 100% 100%;
      img{
           width: 26px;
           margin-top: 40px;
        }
    }
    .matchTitle {
      font-size: 22px;
      font-weight: 600;
    }
    .loadingImg {
      width: 80px;
      height: 63px;
      background-image: url("../../img/da1.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;
      img {
        width: 26px;
        animation: spin 3s linear infinite;
      }
    }
    .arktitle {
      animation: blink 3s linear infinite;
      font-size: 22px;
      color: #fff;
    }

    // 文字闪烁动画
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    // 旋转动画
    @keyframes spin {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0deg);
        transition: all 5s;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 49px;
    .van-button {
      border: none;
      color: #fff;
      width: 140px;
      height: 40px;
      background: #2e99ff;
      border-radius: 2px;
      font-size: 15px;
    }
  }
  .footer {
    height: 60px;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
}
</style>
